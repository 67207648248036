<template>
    <div class="tool-content">
        <div class="left-tool">
            <div class="tool-item" @click="viewRecording">
                <i class="iconfont">&#xe670;</i>
                <span class="tool-text">录音</span>
            </div>
            <div class="tool-item" @click="viewLink">
                <i class="iconfont">&#xe66e;</i>
                <span class="tool-text">链接</span>
            </div>
            <div class="tool-item" @click="viewResources">
                <i class="iconfont">&#xe66f;</i>
                <span class="tool-text">配套材料</span>
            </div>
            <div class="tool-item">
                <el-dropdown class="header-el-dropdown" @command="addTool" :placement="pop_up_position">
                    <span class="tool-text-icon">
                        <i class="add-tool">+</i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">录音</el-dropdown-item>
                        <el-dropdown-item command="2">链接</el-dropdown-item>
                        <el-dropdown-item command="3">配套材料</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!--查看工具弹窗-->
        <el-dialog :visible.sync="viewToolDialog" width="1000px" :close-on-click-modal="false" class="add-tool-dialog"
                   append-to-body @close="handleCloseTool">
            <div class="dialog-header" slot="title">
                <span class="left">{{dialog_view_title}}</span>
                <span class="center" v-if="dialog_view_title === '配套材料'">{{course_name}}第{{zhangIndex+1}}章第{{jieIndex+1}}节</span>
            </div>
            <div class="dialog-content">
                <div class="content-item view-recording-item" v-if="dialog_view_title === '录音'">
                    <div class="select type-list">
                        <span class="select-text">音乐：</span>
                        <el-select v-model="recordingValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectRecording">
                            <el-option
                                    v-for="item in recordingList"
                                    :key="item.id"
                                    :label="item.data.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content other-content" v-if="recordingUrl !==''">
                        <el-button @click="del(recordingId)" class="del-btn"><i class="iconfont">&#xe630;</i></el-button>
                        <audio controls>
                            <source :src="recordingUrl" type="audio/ogg">
                        </audio>
                    </div>
                    <div class="content" v-if="recordingUrl ===''">
                        <div class="no-data">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </div>
                <div class="content-item view-link-item" v-if="dialog_view_title === '链接'">
                    <div class="select type-list">
                        <span class="select-text">名称：</span>
                        <el-select v-model="linkValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectLink">
                            <el-option
                                    v-for="item in linkList"
                                    :key="item.id"
                                    :label="item.data.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content other-content" v-if="linkUrl !==''">
                        <el-button @click="del(linkId)" class="del-btn"><i class="iconfont">&#xe630;</i></el-button>
                        <a :href="linkUrl" target="_blank">{{linkUrl}}</a>
                    </div>
                    <div class="content" v-if="linkUrl ===''">
                        <div class="no-data">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </div>
                <div class="content-item resources-item" v-if="dialog_view_title === '配套材料'">
                    <div class="select type-list">
                        <span class="select-text">类型：</span>
                        <el-select v-model="typeValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectShowType">
                            <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content">
                        <el-scrollbar class="left-list" :native="false">
                            <div class="type-item"
                                 v-for="item in addedMaterialLists"
                                 :class="{current: currentItem === item.data.material_id}"
                                 :title="item.data.title"
                                 @click="selectTypeMaterial(item)">
                                <span>{{item.data.title}}</span>
                                <el-button @click="del(item.id)" class="del-btn"><i class="iconfont">&#xe630;</i></el-button>
                            </div>
                            <div v-if="addedMaterialLists.length === 0" class="no-data">
                                <span>暂无数据</span>
                            </div>
                        </el-scrollbar>
                        <div class="right-list">
                            <div v-if="is_no_data" class="no-data">
                                <span>暂无数据</span>
                            </div>
                            <div v-if="suffix=='avi'">
                                <video v-if="addedMaterialLists.length > 0" :src="`${added_material_content}`" autoplay controls  width="100%" height="100%"
                                    class="iframe-content">
                                </video>
                            </div>
                            <div v-else style="height:100%">
                                <iframe v-if="addedMaterialLists.length > 0" :src="`${added_material_content}`" width="100%" height="100%"
                                    class="iframe-content">
                                </iframe>
                            </div>    
                        </div>
                        <!--<div class="right-list">-->
                            <!--<video :src="require('../../assets/resources/home.mp4')" controls="controls" width="100%"-->
                                   <!--height="100%"></video>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--添加工具弹窗-->
        <el-dialog :visible.sync="addToolDialog" width="1000px" @close="resetAddToolForm()"
                   :close-on-click-modal="false" class="add-tool-dialog" append-to-body>
            <div class="dialog-header" slot="title">
                <span class="left">{{dialog_title}}</span>
                <span class="center" v-if="dialog_title === '添加配套材料'">{{course_name}}第{{zhangIndex+1}}章第{{jieIndex+1}}节</span>
            </div>
            <div class="dialog-content">
                <div class="content-item recording-item" v-if="dialog_title === '添加录音'">
                    <el-form :model="recordingForm" ref="recordingForm" :rules="recordingRules" label-position="center"
                             class="info-form">
                        <el-form-item label="标签：" :label-width="recordingFormLabelWidth" prop="tag_name">
                            <el-input v-model="recordingForm.tag_name" autocomplete="off" placeholder="请输入标签3-10个字"
                                      class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="上传录音：" :label-width="recordingFormLabelWidth" prop="recording"
                                      class="el-form-item-logo">
                            <div class="upload-cover">
                                <i class="iconfont">&#xe659;</i>
                                <span class="upload-text">{{recordingForm.recording ? '重新上传' : '点击上传'}}</span>
                            </div>
                            <input type="file" accept="*" title="" ref="alogo" @change="changeEditRecording($event)"
                                   class="cover-input">
                            <div class="up-img-show" v-show="videoName">
                                <i class="el-icon-document"></i>
                                {{videoName}}
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="content-item recording-item" v-if="dialog_title === '添加链接'">
                    <el-form :model="linkForm" ref="linkForm" :rules="linkRules" label-position="center"
                             class="info-form">
                        <el-form-item label="名称：" :label-width="recordingFormLabelWidth" prop="link_name">
                            <el-input v-model="linkForm.link_name" autocomplete="off" placeholder="请输入名称3-10个字"
                                      class="app-input"></el-input>
                        </el-form-item>
                        <el-form-item label="链接：" :label-width="recordingFormLabelWidth" prop="link_url">
                            <el-input v-model="linkForm.link_url" autocomplete="off" placeholder="请输入链接"
                                      class="app-input"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="content-item resources-item" v-if="dialog_title === '添加配套材料'">
                    <div class="select type-list">
                        <span class="select-text">类型：</span>
                        <el-select v-model="typeValve" placeholder="请选择" style="width: 300px" clearable
                                   @change="selectType">
                            <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content">
                        <el-scrollbar class="left-list" :native="false">
                            <div class="type-item"
                                 v-for="item in typeChildLists"
                                 :class="{current: currentItem === item.id}"
                                 :title="item.title"
                                 @click="selectTypeChild(item)">
                                {{item.title}}
                            </div>
                            <div v-if="typeChildLists.length === 0" class="no-data">
                                <span>暂无数据</span>
                            </div>
                        </el-scrollbar>
                        <div class="right-list">
                            <div v-if="is_no_data" class="no-data">
                                <span>暂无数据</span>
                            </div>
                            <div v-if="suffix=='avi'">
                                <video v-if="typeChildLists.length > 0" :src="`${material_content}`" autoplay controls  width="100%" height="100%"
                                    class="iframe-content">
                                </video>
                            </div>
                            <div v-else style="height:100%">
                                <iframe v-if="typeChildLists.length > 0" :src="material_content" width="100%" height="100%"
                                    class="iframe-content"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="addToolDialog = false" class="dialog-footer-btn">取 消</el-button>
                <el-button size="medium" type="primary" v-if="dialog_title === '添加录音'" @click="submitAddTool(1)"
                           class="dialog-footer-btn">添加
                </el-button>
                <el-button size="medium" type="primary" v-if="dialog_title === '添加链接'" @click="submitAddTool(2)"
                           class="dialog-footer-btn">添加
                </el-button>
                <el-button size="medium" type="primary" v-if="dialog_title === '添加配套材料'" @click="submitAddMaterial(3)"
                           class="dialog-footer-btn">添加
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "WareTool",
        props: ['resource_id','course_name','zhangIndex','jieIndex'],
        data() {
            return {
                course_id: '',
                //弹窗位置
                pop_up_position: 'bottom-start',
                //添加工具弹窗标题
                dialog_title: '',
                //是否显示添加弹窗
                addToolDialog: false,
                //选中的类型
                typeValve: 999,
                //类型类表
                typeList: [],
                typeChildLists: [],
                material_content: '',
                currentItem: '1',
                //已添加的配套材料列表
                addedMaterialLists: [],
                added_material_content: '',
                //录音表单数据
                recordingForm: {
                    tag_name: '',
                    recording: ''
                },
                recordingRules: {
                    tag_name: [
                        {required: true, message: '请输入标签3-10个字', trigger: 'blur'},
                        {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ]
                },
                recordingFormLabelWidth: '120px',
                recordingFile: '',
                //链接表单数据
                linkForm: {
                    link_name: '',
                    link_url: ''
                },
                linkRules: {
                    link_name: [
                        {required: true, message: '请输入标签3-10个字', trigger: 'blur'},
                        {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ],
                    link_url: [
                        {required: true, message: '请输入链接', trigger: 'blur'},
                        {min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur'}
                    ]
                },
                //是否显示查看
                viewToolDialog: false,
                //查看工具弹窗标题
                dialog_view_title: '',
                //选中的录音
                recordingValve: '',
                recordingUrl: '',
                recordingId: '',
                //录音列表
                recordingList: [],
                //选中的链接
                linkValve: '',
                linkUrl: '',
                linkId: '',
                //链接列表
                linkList: [],
                videoName: '',
                videoPath: [],
                //配套材料-暂无数据
                is_no_data: false,
                resId:void 0,
                suffix:'',
            }
        },
        mounted() {
            this.course_id = this.$route.query.course_id;
            this.getTypeList();
        },
        methods: {
            getTypeList() {
                this.$thttp.axiosGet(this.$api.meterial_mtlTypeList, (res) => {
                    if (res.code === 200) {
                        this.typeList = res.data;
                        let obj = {
                            id: 999,
                            name: '全部',
                            type: 0
                        }
                        this.typeList.splice(0, 0, obj);
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择要添加的工具
            addTool(command) {
                this.addToolDialog = true;
                if (command === '1') {
                    this.dialog_title = '添加录音';
                }
                if (command === '2') {
                    this.dialog_title = '添加链接';
                }
                if (command === '3') {
                    this.dialog_title = '添加配套材料';
                    this.getMaterialList();
                }
            },
            //选中的类型
            selectType(val) {
                console.log(val)
                this.typeValve = val;
                this.getMaterialList();
                // if (val === 8) {
                //     this.oneMaterial();
                // } else {
                //     this.getMaterialList();
                // }
            },
            selectTypeChild(item) {
                this.typeValve=item.material_type_id?item.material_type_id:item.meterial_type_id
                this.resId=item.resource_id?item.resource_id:void 0
                this.currentItem = item.id;
                this.getMaterialContent(item.id);
            },
            selectShowType(val) {
                if (val === 999) {
                    this.getAllCourseWare();
                } else {
                    this.getCourseWare(3);
                }
            },
            selectTypeMaterial(item) {
                console.log(item)
                this.currentItem = item.data.material_id;
                this.getAddedMaterialContent(item.data.material_id);
            },
            openPerson(item) {
                this.currentItem = item.id;
                this.person_view_url = item.file_path.src;
            },
            //上传录音
            changeEditRecording(event) {
                let modelName = event.target.files[0].name;
                let limiteFileType = ["mp3", 'wav', 'midi', 'ogg', 'vqf'];
                if (
                    !limiteFileType.includes(
                        modelName.split(".")[modelName.split(".").length - 1].toLowerCase()
                    )
                ) {
                    this.$message.warning("文件格式错误！");
                    return;
                }
                let fileList = event.target.files[0];
                let formData = new FormData();
                formData.append("file", fileList);
                this.$thttp.axiosPost(this.$api.courseware_uploadWareFiles, formData, (res) => {
                    if (res.code === 200) {
                        this.videoName = modelName;
                        this.videoPath.push(res.data.url);
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    this.$message.error("文件上传失败")
                    reject(err)
                })
            },
            //重置弹窗
            resetAddToolForm() {
                this.addToolDialog = false;
                this.typeValve = 999;
                this.linkForm.link_name = '';
                this.linkForm.link_url = '';
                this.recordingForm.tag_name = '';
                this.recordingForm.recording = '';
            },
            //查看工具弹窗
            viewRecording() {
                this.viewToolDialog = true;
                this.dialog_view_title = '录音';
                this.getCourseWare(1);
            },
            viewLink() {
                this.viewToolDialog = true;
                this.dialog_view_title = '链接';
                this.getCourseWare(2);
            },
            viewResources() {
                this.viewToolDialog = true;
                this.dialog_view_title = '配套材料';
                if (this.typeValve === 999) {
                    this.getAllCourseWare();
                } else {
                    this.getCourseWare(3);
                }
            },
            oneMaterial() {
                this.$thttp.axiosGet(this.$api.meterial_showMeterialList, (res) => {
                    if (res.code === 200) {
                        if (res.data.data.length > 0) {
                            this.is_no_data = false;
                            this.typeChildLists = res.data.data;
                            this.currentItem = res.data.data[0].id;
                            this.getMaterialContent(res.data.data[0].id);
                        } else {
                            this.is_no_data = true;
                        }
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            },
            getCourseWare(type) {
                let param = {
                    resource_id: this.resource_id,
                    type: type,
                    show: 'all',
                }
                if (type === 3) {
                    param.material_type_id = this.typeValve;
                }
                this.$thttp.axiosGetBy(this.$api.courseware_showCourseWare, param, (res) => {
                    if (res.code === 200) {
                        if (type === 1) {
                            this.recordingList = res.data;
                            if (res.data.length > 0) {
                                this.recordingValve = res.data[0].id;
                                this.recordingUrl = res.data[0].data.url.url;
                                this.recordingId = res.data[0].id;
                            }
                        } else if (type === 2) {
                            this.linkList = res.data;
                            if (res.data.length > 0) {
                                this.linkValve = res.data[0].id;
                                this.linkUrl = res.data[0].data.url;
                                this.linkId = res.data[0].id;
                            } else {
                                this.linkList = [];
                                this.recordingValve = '';
                            }
                        } else {
                            this.addedMaterialLists = res.data;
                            if (res.data.length > 0) {
                                this.is_no_data = false;
                                this.currentItem = res.data[0].data.material_id;
                                this.getAddedMaterialContent(res.data[0].data.material_id);
                            } else {
                                this.is_no_data = true;
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取所有配套材料
            getAllCourseWare() {
                let param = {
                    resource_id: this.resource_id,
                    type: 3,
                    show: 'all',
                }
                this.$thttp.axiosGetBy(this.$api.courseware_showCourseWare, param, (res) => {
                    if (res.code === 200) {
                        this.addedMaterialLists = res.data;
                        if (res.data.length > 0) {
                            this.is_no_data = false;
                            this.currentItem = res.data[0].data.material_id;
                            this.getAddedMaterialContent(res.data[0].data.material_id);
                        } else {
                            this.is_no_data = true;
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            handleCloseTool() {
                this.recordingList = [];
                this.linkList = [];
                this.recordingUrl = '';
                this.recordingId = '';
                this.linkUrl = '';
                this.linkId = '';
                this.recordingValve = '';
                this.linkValve = '';
                this.typeValve = 999;
            },
            //选中的录音
            selectRecording(val) {
                this.recordingList.forEach(item => {
                    if(item.id === val){
                        this.recordingUrl = item.data.url.url;
                        this.recordingId = item.id;
                    }
                })
                if(val === ''){
                    this.recordingUrl = '';
                    this.recordingId = '';
                }
            },
            //选中的链接
            selectLink(val) {
                this.linkList.forEach(item => {
                    if(item.id === val){
                        this.linkUrl = item.data.url;
                        this.linkId = item.id;
                    }
                })
                if(val === ''){
                    this.linkUrl = '';
                    this.linkId = '';
                }
            },
            //获取配套材料列表
            getMaterialList() {
                let param = {
                    course_id: this.$route.query.course_id,
                    course_chapter: this.zhangIndex,
                    course_node: this.jieIndex,
                    type: 'all'
                }
                if (this.typeValve !== 999) {
                    param.meterial_type_id = this.typeValve;
                }
                this.$thttp.axiosGetBy(this.$api.meterial_showMeterialList, param, (res) => {
                    if (res.code === 200) {
                        this.typeChildLists = res.data;
                        if (res.data.length > 0) {
                            this.is_no_data = false;
                            this.currentItem = res.data[0].id;
                            this.getMaterialContent(res.data[0].id);
                        } else {
                            this.is_no_data = true;
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取配套材料对应资源
            getMaterialContent(id) {
                this.typeChildLists.forEach((item) => {
                    if (item.id === id) {
                        this.material_content = item.file_preview_path.url?item.file_preview_path.url:item.file_preview_path;
                        this.suffix=this.material_content.substring(this.material_content.lastIndexOf('.')+1)
                    }
                })
            },
            //获取已添加对的配套材料对应资源
            getAddedMaterialContent(id) {
                this.addedMaterialLists.forEach((item) => {
                    if (item.data.material_id === id) {
                        this.added_material_content = item.data.file_preview_path.url?item.data.file_preview_path.url:item.data.file_preview_path;
                    }
                    this.suffix=this.added_material_content.substring(this.added_material_content.lastIndexOf('.')+1)
                })
            },
            //添加
            submitAddTool(type) {
                let obj = {};
                switch (type) {
                    case 1:
                        if (this.videoPath.length === 0) {
                            this.$message.warning('请上传文件！')
                            return
                        }
                        obj.name = this.recordingForm.tag_name;
                        obj.url = this.videoPath[0];
                        break;
                    case 2:
                        obj.name = this.linkForm.link_name;
                        let url = this.linkForm.link_url;
                        let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/&&&])+$/;
                        if (!reg.test(url)) {
                            this.$message.warning("这网址不是以http://https://开头，或者不是网址！");
                            return;
                        }
                        obj.url = this.linkForm.link_url;
                        break;
                }
                let param = {};
                param.data = JSON.stringify(obj);
                param.resource_id = this.resource_id;
                param.type = type;
                this.$thttp.axiosPost(this.$api.courseware_saveCourseWare, param, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.resetAddToolForm();
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    this.$message.error("文件上传失败")
                    reject(err)
                })
            },
            //添加配套材料
            submitAddMaterial(type) {
                let obj = {}
                obj.material_type_id = this.typeValve;
                
                if(this.resId){
                    obj.resource_id = this.currentItem;
                }else{
                    obj.material_id = this.currentItem;
                }
                let param = {};
                param.data = JSON.stringify(obj);
                param.resource_id = this.resource_id;
                param.type = type;
                this.$thttp.axiosPost(this.$api.courseware_saveCourseWare, param, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.resetAddToolForm();
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    this.$message.error(err);
                })
            },
            //删除图钉
            del(id) {
                this.$confirm('是否删除，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    this.$thttp.axiosGetBy(this.$api.courseware_delCourseWare, {id: id}, (res) => {
                        if (res.code === 200) {
                            if (this.dialog_view_title === '录音') {
                                this.getCourseWare(1);
                            }
                            if (this.dialog_view_title === '链接') {
                                this.getCourseWare(2);
                                this.linkValve = '';
                                this.linkUrl = '';
                            }
                            if (this.dialog_view_title === '配套材料') {
                                this.getCourseWare(3);
                            }
                            this.$message.success(res.msg);
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-tool {
        position: absolute;
        z-index: 999;
        width: 80px;
        /*height: calc(100% - 150px);*/
        height: calc(100% - 34px);
        background-color: #333;
        /*right: 100px;*/
        left: 0;
        top: 36px;
        padding-top: 80px;
        box-sizing: border-box;

        .tool-item {
            color: #fff;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;

            i.iconfont {
                margin-bottom: 10px;
            }

            .tool-text-icon {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #BFBFBF;
                text-align: center;
                line-height: 20px;
                display: block;
            }

            .add-tool {
                font-size: 20px;
                color: #fff;
                display: block;
                width: 20px;
                height: 20px;
                font-style: initial;
            }

            &:nth-child(2) i.iconfont {
                font-size: 14px;
            }

            &:nth-child(3) i.iconfont {
                font-size: 18px;
            }
        }
    }

    .add-tool-dialog {
        ::v-deep .el-dialog {
            border-radius: 6px;
            .el-dialog__close {
                color: #fff;
            }
        }

        ::v-deep .el-dialog__header {
            padding: 15px 20px;
            color: #fff;
            font-size: 16px;
            background-color: #409EFF;
            border-radius: 6px 6px 0 0;

            .dialog-header {
                display: flex;
                position: relative;

                .center {
                    position: absolute;
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                }
            }

            ::v-deep .el-dialog__headerbtn .el-dialog__close {
                color: #fff;
            }
        }

        ::v-deep .el-dialog__body {
            /*height: 467px;*/
            padding: 0;

            .dialog-content {
                height: 100%;
                background-color: #F2F2F2;

                .content-item {
                    display: flex;
                    flex-direction: column;
                }

                .resources-item {
                    /*height: 467px;*/
                    /*height: 667px;*/
                    height: 567px;
                }

                .recording-item {
                    background-color: #fff;
                    height: 267px;
                }

                .view-recording-item {
                    height: 447px;

                    .content {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 13px;
                        background-color: #fff;
                        .recording-name {
                            height: 60px;
                        }
                    }
                }

                .view-link-item {
                    background-color: #fff;
                    height: 320px;

                    .content {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #F2F2F2;
                        margin: 20px;
                    }
                }

                .type-list {
                    display: flex;
                    align-items: center;
                    height: 80px;
                    background-color: #fff;
                    padding: 0 20px;
                }

                .content {
                    display: flex;
                    flex: 1;
                    height: 1%;
                    padding: 13px;

                    .left-list {
                        width: 200px;
                        height: 100%;
                        background-color: #fff;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;

                            .el-scrollbar__view {
                                height: 100%;
                            }
                        }

                        .type-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin: 10px;
                            height: 29px;
                            line-height: 29px;
                            padding-left: 10px;
                            color: #333;
                            font-size: 14px;
                            cursor: pointer;
                            width: calc(100% - 30px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            &:hover {
                                background-color: #ECF5FF;
                                color: #409EFF;
                            }
                        }

                        .current {
                            background-color: #ECF5FF;
                            color: #409EFF;
                        }
                    }

                    .right-list {
                        flex: 1;
                        width: 1%;
                        margin: 22px 22px 22px 35px;
                        box-sizing: border-box;
                        /*background-color: rebeccapurple;*/
                    }
                }
            }
        }

        ::v-deep .el-dialog__footer {
            padding: 20px;

            .dialog-footer {
                .dialog-footer-btn {
                    padding: 12px 20px;
                }
            }
        }
    }

    .info-form {
        padding: 30px;

        .el-form-item-logo {
            margin-bottom: 0;
        }

        .upload-cover {
            position: relative;
            background-color: #DCECFE;
            border-color: #DCECFE;
            padding: 0;
            width: 100px;
            height: 30px;
            color: #409EFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            border-radius: 4px;
            margin-top: 5px;

            i {
                width: 16px;
                height: 16px;
                line-height: 16px;
                margin: 5px;
            }

            span.upload-text {
                display: block;
                height: 100%;
                line-height: 30px;
            }
        }

        .cover-input {
            position: absolute;
            width: 100px;
            height: 30px;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }

        .up-img-show {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 300px;
            height: 30px;
            top: 0;
            left: 100px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .del-btn {
        padding: 2px 6px;
        background-color: #FF0000;
        border: 1px solid #FF0000;
        color: #fff;
        margin-right: 5px;
    }
    .other-content {
        position: relative;
        .del-btn {
            position: absolute;
            top: 14px;
            right: 10px;
        }
    }
</style>